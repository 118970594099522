import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import qullSnow from 'quill/dist/quill.snow.css?url';
import { ReactNode } from 'react';
import reactDatepicker from 'react-datepicker/dist/react-datepicker.css?url';
import { ToastContainer } from 'react-toastify';
import ReactToastify from 'react-toastify/dist/ReactToastify.css?url';
import cdtpicker from '@assets/common/dtpicker.scss?url';
import mainLoading from '@assets/common/main.loading.scss?url';
import quillCustom from '@assets/common/quill.custom.scss?url';

import ModalContainer from '@components/admin/modal/ModalContainer';
import { useToast } from '@hooks/useToast';
import { getToast } from '@server/toast.server';
import { combineHeaders } from '@server/utils/common';

export const links: LinksFunction = () => [
    { rel: 'stylesheet', href: reactDatepicker },
    { rel: 'stylesheet', href: cdtpicker },
    { rel: 'stylesheet', href: mainLoading },
    { rel: 'stylesheet', href: ReactToastify },
    { rel: 'stylesheet', href: qullSnow },
    { rel: 'stylesheet', href: quillCustom },
];

export async function loader({ request }: LoaderFunctionArgs) {
    const { toast, headers: toastHeaders } = await getToast(request);

    return json(
        {
            toast,
        },
        {
            headers: combineHeaders(toastHeaders),
        },
    );
}

export function Layout({ children }: { children: ReactNode }) {
    return (
        <html lang="ko">
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="naver-site-verification" content="8d64e72bf26f9dba4116f7425a17eb7f96dce838" />
                <Meta />
                <Links />
            </head>
            <body>
                {children}
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>
    );
}

export default function App() {
    const data = useLoaderData<typeof loader>();
    useToast(data.toast);

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Outlet />
            <ModalContainer />
        </>
    );
}
